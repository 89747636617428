import {
  searchAggregatesByRelation,
  searchAggregateDrill,
  getVendor,
  searchPayees,
  searchExpenseDiversity,
  getDiversityReferenceData,
  searchAggregateProcurementByType,
  getIndigenousCommunityClients,
  getReportMatrix,
  getExpenseAggregates,
  getProject,
  getProjectPayees,
  searchExpenses,
  getEmploymentAggregates,
  getShortList,
  searchAggregatesById,
  getVendors,
  getReferenceData,
  getEmploymentAnalysis,
  getEmploymentHeadcount,
  getEidaEmployment,
} from "../store/rest.service";
import {
  EmptyGeneralSearchCriteria,
  EmptyCommunityInvestmentTreeMap,
  EmptyVendor,
  EmptyAggSearchCriteria,
  EmptyPayeeSearchCriteria,
  EmptyReportDataTable,
  EmptyTotalsAggregateValue,
  EmptyReportDataTableRow,
  EmptyEIRDatas,
  EmptyData,
  EmptySearchParams,
  EmptySearchExpenses,
} from "../store/models-empty";
import {
  ResourceSearchResult,
  GeneralSearchCriteria,
  OccupationAggregate,
  CommunityInvestmentTreeMap,
  CommunityInvestmentTreeMapParent,
  CommunityInvestmentTreeMapChild,
  AggregateSeriesDrilldown,
  DrilldownSeriesTypeBenefitMeta,
  NamedAggregateValue,
  TotalsAggregateValue,
  ShortRecord,
  Vendor,
  AggSearchCriteria,
  PayeeSearchCriteria,
  DiversityTotalsMeta,
  ReportDataTable,
  DiversityTotalsType,
  DiversityReferenceData,
  Aggregate,
  ReportDataTableRow,
  DiversityTypePayee,
  ProcurementProjectsMeta,
  ProcurementPayeesMeta,
  ProcurementTypeMeta,
  ProcurementTypePayeesType,
  ProcurementBeneficiaryPayeesType,
  EIRData,
  DataServiceAggregateQuery,
  Project,
  DiversityDeclarationsMeta,
  DiversityDeclarationMeta,
  DiversityRefDataCategory,
  DiversityRefDataValue,
  SearchParams,
  SearchExpenses,
  Expense,
  EIRReportMeta,
  EmploymentAggregate,
} from "../store/models";
import { convertToTotalsAggregateValue } from "../lib/aggregate";
import { formatCurrencyToString, getDiversityNameFromCode, handleError, mapSearchParams, sortResultsAlphabetically } from "../lib/shared";
import * as colors from "../lib/colors";
import { toIsoDateString } from "../lib/datetime";
import * as _ from "lodash";
import * as datetime from "../lib/datetime";
import { store } from "../store/VuexStore.js";

export enum ReportType {
  Insights,
  InsightsProject,
  IndigenousImpactSummary,
  IndigenousImpactProject,
  IndigenousImpactCommunity,
  SocialImpactCorporate,
  SocialImpactProject,
  SocialImpactLocation,
  SocialProcurementCorporate,
  SocialProcurementProject,
  SocialProcurementCommunitySummary,
  SocialProcurementCommunity,
  SocialProcurementCommunityProject,
  DiversityInEmploymentCorporate,
  DiversityInEmploymentProject,
  TangibleNetBenefitsSummary,
  TangibleNetBenefitsProject,
  TangibleNetBenefitsCommunity,
  TangibleNetBenefitsCommunityClient,
  SpecialityCanadianCouncil,
  SpecialityBCorpCertified,
  SpecialityEquityInclusion,
  SpecialityEquityInclusionProject,
  CompactEquityInclusion,
  UNSustainableDevelopment,
  DSCProjectSocialImpact,
  DSCProjectIndigenousImpact,
  PEHTAProject,
  PEHTACorporate,
  InclusionDevelopmentAgreement,
  Unknown,
}

export default class ReportHelper {
  public isLoading: boolean = true;
  public arrErrors: Error[] = [];
  public beneficiaryAggregateResponse: any = null;
  public payerAggregateResponse: any = null;
  public clientsResponse: any = null;
  public communityClientsResponse: any = null;
  public icprojectsAggregateResponse: any = null;
  public employmentByCommunityAggregateResponse: any = {};
  public employmentByOccupationAggregateResponse: any = {};
  public employmentByOccupationAggregate: OccupationAggregate[] = [];
  public expenseDiversityAggregateResponse: any = {};
  public expenseDiversityReportDataTables: ReportDataTable[] = [];

  public indigenousPayeeProcurementAggregateResponse: any = {};
  public indigenousPayeeProcurementAggregateLoading: boolean = false;
  public projectSummaryReportDataTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  public payeeCommunityReportDataTables: ReportDataTable[] = [];
  public payeeCommunityReportFlattenedDataTables: ReportDataTable[] = [];

  public indigenousProcurementAggregateResponse: any = {};
  public indigenousProcurementAggregateLoading: boolean = false;
  public indigenousTypeReportDataTables: ReportDataTable[] = [];

  public summaryIndigenousProcurementAggregateResponse: any = {};
  public summaryIndigenousProcurementAggregateLoading: boolean = false;
  public summaryReportDataTables: ReportDataTable[] = [];

  public communityIndigenousProcurementAggregateResponse: any = {};
  public communityIndigenousProcurementAggregateLoading: boolean = false;
  public communityReportDataTables: ReportDataTable[] = [];

  public diversityReferenceData: DiversityReferenceData | null = null;
  public diversityReferenceDataLoading: boolean = false;

  // For community investment we must call multiple endpoints and populate a list of parents and children.
  public communityInvestmentTreeMap: CommunityInvestmentTreeMap = _.cloneDeep(EmptyCommunityInvestmentTreeMap);
  public communityInvestmentReportItems: NamedAggregateValue[] = [];
  public communityInvestmentTotal: TotalsAggregateValue | null = null;

  public EIRLevelData = _.cloneDeep(EmptyEIRDatas);
  public EIRLocationData = _.cloneDeep(EmptyEIRDatas);
  public EIRHourlyOccupationData = _.cloneDeep(EmptyEIRDatas);
  public EIRTotalData = _.cloneDeep(EmptyEIRDatas);
  public EIRHeadcountData: any = {};

  public CCABYearlyDiversityData: Record<string, Record<string, EIRData>> = {};

  public expenseData: any = {};
  public projectExpenseData: any = {};

  public overheadExpenseData: any = {};
  public overheadServicesExpenseData: any = {};
  public overheadGoodsExpenseData: any = {};
  public overheadEquipmentExpenseData: any = {};
  public overheadContributionsExpenseData: any = {};

  public projectServicesExpenseData: any = {};
  public projectEquipmentExpenseData: any = {};
  public projectGoodsExpenseData: any = {};
  public projectContributionExpenseData: any = {};
  public projectRevenueExpenseData: any = {};

  public inclusionDevelopmentAgreementData: any = {};

  public pehtaData: any = {};
  public indigenousCommunities: any = {};
  public unsdgs: any[] = [];

  public projectDetails: any = {};
  public payeeDetails: any = {};

  public searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  public aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
  public payeeSearchCriteria: PayeeSearchCriteria = _.cloneDeep(EmptyPayeeSearchCriteria);

  public id: string = "";
  public reportType: ReportType;
  public payeeHelperLoading: boolean = false;

  public projectHeaders: any[] = [
    { text: "Project Name", align: "left", sortable: false, value: "project.projectName", class: "grid-header" },
    { text: "Client", align: "left", sortable: false, value: "subproject.creatorVendor.displayName", class: "grid-header" },
    { text: "Total Project <br>Revenue", align: "left", value: "totals.spend.totalProjectRevenue", sortable: false, class: "grid-header" },
    { text: "Direct Indigenous <br>Spend", align: "left", value: "totals.spend.totalIndigenousSpend", sortable: false, class: "grid-header" },
    { text: "Project <br>Benefits", align: "left", value: "totals.benefit.projectBenefit.submitted", sortable: false, class: "grid-header color-project-benefits" },
    { text: "Indigenous <br>Employment", align: "left", value: "totals.employment.totalIndigenousEmploymentValue", sortable: false, class: "grid-header color-employment" },
  ];

  public employmentHeaders: any[] = [
    { text: "Occupation", align: "left", sortable: false, value: "occupationName", class: "grid-header color-employment-bg color-white" },
    { text: "Hours", align: "right", sortable: false, value: "totalIndigenousManHours", class: "grid-header color-employment-bg color-white" },
    { text: "Total Wages", align: "right", sortable: false, value: "totalIndigenousEmploymentValue", class: "grid-header color-employment-bg color-white" },
  ];

  public totalIndigenousCommunityInvestmentHeaders: any[] = [
    { text: "Investment Category", align: "left", sortable: true, value: "name", class: "grid-header" },
    { text: "Total Spend", align: "right", sortable: true, value: "value.value", class: "grid-header" },
    { text: "Percentage of Total Spend", align: "right", sortable: true, value: "value.percentage", class: "grid-header" },
  ];

  public socialProcurementHeaders: any[] = [
    { text: "Social Procurement<br>Category", align: "left", sortable: false, class: "grid-header grid-bold-text" },
    { text: "Goods<br>($/%)", align: "right", sortable: false, class: "grid-header grid-bold-text" },
    { text: "Services<br>($/%)", align: "right", sortable: false, class: "grid-header grid-bold-text" },
    { text: "Equipment<br>($/%)", align: "right", sortable: false, class: "grid-header grid-bold-text" },
    { text: "Uncategorized<br>($/%)", align: "right", sortable: false, class: "grid-header grid-bold-text" },
    { text: "Total<br>($/%)", align: "right", sortable: false, class: "grid-header grid-bold-text" },
  ];

  public tnbHeaders: any[] = [
    { text: "Type", align: "left", sortable: false, value: "type", class: "grid-header grid-bold-text" },
    { text: "Project Benefits", align: "right", sortable: false, value: "projectBenefits.percentage", class: "grid-header grid-bold-text" },
    { text: "Employment", align: "right", sortable: false, value: "employment.percentage", class: "grid-header grid-bold-text" },
    { text: "Total", align: "right", sortable: false, value: "total.percentage", class: "grid-header grid-bold-text" },
  ];

  public workforceDiversityHeaders: any[] = [
    { text: "Worker Category", align: "left", sortable: false, value: "workerCategory", class: "grid-header grid-bold-text" },
    { text: "Total Wages", align: "right", sortable: false, value: "totalWages", class: "grid-header grid-bold-text" },
    { text: "Employment Hours (#/%)", align: "right", sortable: false, value: "totalHours", class: "grid-header grid-bold-text" },
    { text: "Avg. Wage/Hour", align: "right", sortable: false, value: "avgWagePerHour", class: "grid-header grid-bold-text" },
    { text: "No. of Workers (#/%)", align: "right", sortable: false, value: "noOfWorkers", class: "grid-header grid-bold-text" },
  ];

  public workforceDiversityReportHeaders: any[] = [
    { text: "Worker Category", rowLayout: 4, align: "left" },
    { text: "Total Wages", rowLayout: 2, align: "right" },
    { text: "Employment Hours (#/%)", rowLayout: 2, align: "right" },
    { text: "Avg. Wage/Hour", rowLayout: 2, align: "right" },
    { text: "No. of Workers (#/%)", rowLayout: 2, align: "right" },
  ];

  public workforceJobCreationHeaders: any[] = [
    { text: "Worker Category", align: "left", sortable: true, value: "workerCategory", class: "grid-header grid-bold-text" },
    { text: "FTE (#/%)", align: "right", sortable: true, value: "totalFTE.percentage", class: "grid-header grid-bold-text" },
    { text: "Employment Hours (#/%)", align: "right", sortable: true, value: "totalHours.percentage", class: "grid-header grid-bold-text" },
  ];

  public employmentByCommunityHeaders: any[] = [
    {
      text: "Community",
      align: "left",
      sortable: true,
      value: "facetDetail.displayName",
      class: "grid-header",
    },
    {
      text: "Total Wages",
      align: "right",
      sortable: true,
      value: "totals.employment.totalIndigenousEmploymentValue",
      class: "grid-header",
    },
    {
      text: "Hours",
      align: "right",
      sortable: true,
      value: "totals.employment.totalIndigenousManHours",
      class: "grid-header",
    },
  ];

  public employmentByOccupationHeaders: any[] = [
    {
      text: "Occupation",
      align: "left",
      sortable: true,
      value: "occupationName",
      class: "grid-header",
    },
    {
      text: "Indigenous Totals",
      align: "right",
      sortable: true,
      value: "totalIndigenousEmploymentValue",
      class: "grid-header",
    },
    {
      text: "Non-Indigenous Totals",
      align: "right",
      sortable: true,
      value: "totalNonIndigenousEmploymentValue",
      class: "grid-header",
    },
  ];

  public indigneousProcurementSummaryProjectHeaders: any[] = [
    { text: "", rowLayout: 6, align: "left" },
    { text: "", rowLayout: 3, align: "left" },
    { text: "", rowLayout: 3, align: "right" },
  ];

  public indigneousProcurementSummaryHeaders: any[] = [
    { text: "", rowLayout: 6, align: "left" },
    { text: "", rowLayout: 6, align: "right" },
  ];

  public diversityBreakdownHeaders: any[] = [
    { text: "Company", rowLayout: 4, align: "left", style: "font-size: 11pt" },
    { text: "Goods ($/%)", rowLayout: 2, align: "right" },
    { text: "Services ($/%)", rowLayout: 2, align: "right" },
    { text: "Equipment ($/%)", rowLayout: 2, align: "right" },
    { text: "Total ($/%)", rowLayout: 2, align: "right" },
  ];

  public diversityVendorBreakdownHeaders: any[] = [
    { text: "Company", rowLayout: 10, align: "left", style: "font-size: 11pt" },
    { text: "Total ($/%)", rowLayout: 2, align: "right" },
  ];

  public occupationHeadersHours: any[] = [
    { text: "Occupation", align: "left", rowLayout: 2 },
    { text: "Indigenous Employment Hours", align: "right", rowLayout: 2 },
    { text: "Equity Seeking (Less Indigneous) Employment Hours", align: "right", rowLayout: 2 },
    { text: "Not Equity Seeking Employment Hours", align: "right", rowLayout: 2 },
    { text: "Undeclared / Unknown Employment Hours", align: "right", rowLayout: 2 },
    { text: "Total Employment Hours", align: "right", rowLayout: 2 },
  ];

  public occupationHeadersHourlyRate: any[] = [
    { text: "Occupation", align: "left", rowLayout: 2 },
    { text: "Indigenous Average Rate", align: "right", rowLayout: 2 },
    { text: "Equity Seeking (Less Indigneous) Average Rate", align: "right", rowLayout: 2 },
    { text: "Not Equity Seeking Average Rate", align: "right", rowLayout: 2 },
    { text: "Undeclared / Unknown Average Rate", align: "right", rowLayout: 2 },
    { text: "Average Rate", align: "right", rowLayout: 2 },
  ];

  private socialSummaryHeaders: any[] = [
    { text: "Social Procurement Summary", rowLayout: 8, align: "left" },
    { text: "$", rowLayout: 2, align: "right" },
    { text: "%", rowLayout: 2, align: "right" },
  ];

  private indigenousBenefitsSummaryHeaders: any[] = [
    { text: "Total Project Revenue", rowLayout: 4, align: "left" },
    { text: "Project Benefits ($/%)", rowLayout: 2, align: "left" },
    { text: "Community Contribution ($/%)", rowLayout: 2, align: "left", id: "community-contribution" },
    { text: "Indigenous Employment ($/%)", rowLayout: 2, align: "left", id: "indigenous-employment" },
    { text: "Tangible Net Benefits ($/%)", rowLayout: 2, align: "left" },
  ];

  private contributionsByCommunityHeaders: any[] = [
    { text: "Community", rowLayout: 4, align: "left" },
    { text: "Project Benefits ($)", rowLayout: 2, align: "left" },
    { text: "Community Contribution ($)", rowLayout: 2, align: "left", id: "community-contribution" },
    { text: "Indigenous Employment ($)", rowLayout: 2, align: "left", id: "indigenous-employment" },
    { text: "Tangible Net Benefits ($)", rowLayout: 2, align: "left" },
  ];

  private contributionsByClientHeaders: any[] = [
    { text: "Client", rowLayout: 3, align: "left" },
    { text: "Project Benefits ($)", rowLayout: 3, align: "left" },
    { text: "Indigenous Employment ($)", rowLayout: 3, align: "left", id: "indigenous-employment" },
    { text: "Total Community Benefit ($)", rowLayout: 3, align: "left" },
  ];

  private socialCategoryHeaders: any[] = [
    { text: "Social Procurement Category", rowLayout: 2, align: "left" },
    { text: "Goods ($/%)", rowLayout: 2, align: "right" },
    { text: "Services ($/%)", rowLayout: 2, align: "right" },
    { text: "Equipment ($/%)", rowLayout: 2, align: "right" },
    { text: "Uncategorized ($/%)", rowLayout: 2, align: "right" },
    { text: "Total ($/%)", rowLayout: 2, align: "right" },
  ];

  private yearlyExpenseContributionsParentHeaders: any[] = [
    { text: "", rowLayout: 1, align: "left" },
    { text: "Project Benefit", rowLayout: 3, align: "left" },
    { text: "Community Contribution", rowLayout: 3, align: "left" },
    { text: "Indigenous Employment", rowLayout: 3, align: "left", id: "indigenous-employment" },
  ];

  private yearlyExpenseContributionsHeaders: any[] = [
    { text: "", rowLayout: 2, align: "left" },
    { text: "Contribution", rowLayout: 1, align: "left" },
    { text: "Target", rowLayout: 1, align: "left" },
    { text: "Actual", rowLayout: 1, align: "left" },
    { text: "Contribution", rowLayout: 1, align: "left" },
    { text: "Target", rowLayout: 1, align: "left" },
    { text: "Actual", rowLayout: 1, align: "left" },
    { text: "Contribution", rowLayout: 1, align: "left", id: "indigenous-employment" },
    { text: "Target", rowLayout: 1, align: "left", id: "indigenous-employment" },
    { text: "Actual", rowLayout: 1, align: "left", id: "indigenous-employment" },
  ];

  private yearlySocialCategoryHeaders: any[] = [
    { text: "", rowLayout: 2, align: "left" },
    { text: "Service", rowLayout: 1, align: "left" },
    { text: "Goods", rowLayout: 1, align: "left" },
    { text: "Rental", rowLayout: 1, align: "left" },
    { text: "Total", rowLayout: 1, align: "left" },
    { text: "Target", rowLayout: 1, align: "left" },
    { text: "Actual", rowLayout: 1, align: "left" },
  ];

  private locationProjectsExpenseHeaders: any[] = [
    { text: "", rowLayout: 2, align: "left" },
    { text: "Total Revenue", rowLayout: 1, align: "left" },
    { text: "Total Expenses", rowLayout: 1, align: "left" },
    { text: "Social Procurement", rowLayout: 1, align: "left" },
    { text: "Indigenous Benefits", rowLayout: 1, align: "left" },
    { text: "Employment Equity", rowLayout: 1, align: "left" },
  ];

  private locationSummaryOverheadsHeaders: any[] = [
    { text: "", rowLayout: 3, align: "left" },
    { text: "Total Expenses", rowLayout: 1, align: "left" },
    { text: "Social Procurement", rowLayout: 1, align: "left" },
    { text: "Indigenous Benefits", rowLayout: 1, align: "left" },
    { text: "Employment Equity", rowLayout: 1, align: "left" },
  ];

  private locationContributionExpenseHeaders: any[] = [
    { text: "", rowLayout: 2, align: "left" },
    { text: "Project Contributions", rowLayout: 2, align: "left" },
    { text: "Overhead Contributions", rowLayout: 2, align: "left" },
    { text: "Total Contributions", rowLayout: 1, align: "left" },
  ];

  private locationProjectsSummaryHeaders: any[] = [
    { text: "Project Name", rowLayout: 2, align: "left" },
    { text: "Total Revenue", rowLayout: 1, align: "left" },
    { text: "Total Expenses", rowLayout: 1, align: "left" },
    { text: "Social Procurement", rowLayout: 1, align: "left" },
    { text: "Indigenous Benefits", rowLayout: 1, align: "left" },
    { text: "Employment Equity", rowLayout: 1, align: "left" },
  ];

  private locationProjectsPayeeHeaders: any[] = [
    { text: "Payees", rowLayout: 1, align: "left" },
    { text: "Type", rowLayout: 1, align: "left" },
    { text: "Expense", rowLayout: 1, align: "left" },
    { text: "Local", rowLayout: 1, align: "left" },
    { text: "Indigenous Community Relationship", rowLayout: 1, align: "left" },
    { text: "Indigenous Community Ownership", rowLayout: 1, align: "left" },
    { text: "Diversity Class", rowLayout: 1, align: "left" },
  ];

  private locationProjectsContributionHeaders: any[] = [
    { text: "Payees", rowLayout: 2, align: "left" },
    { text: "Contribution", rowLayout: 1, align: "left" },
    { text: "Indigenous Community", rowLayout: 2, align: "left" },
    { text: "Diversity Class", rowLayout: 2, align: "left" },
  ];

  private locationOverheadsPayeeHeaders: any[] = [
    { text: "Payees", rowLayout: 1, align: "left" },
    { text: "Type", rowLayout: 1, align: "left" },
    { text: "Expense", rowLayout: 1, align: "left" },
    { text: "Indigenous Community Ownership", rowLayout: 2, align: "left" },
    { text: "Diversity Class", rowLayout: 2, align: "left" },
  ];

  private locationOverheadContributionsHeaders: any[] = [
    { text: "Payees", rowLayout: 2, align: "left" },
    { text: "Spend", rowLayout: 1, align: "left" },
    { text: "Indigenous Community", rowLayout: 2, align: "left" },
    { text: "Diversity Class", rowLayout: 2, align: "left" },
  ];

  private beneficiaryAggregateLoading: boolean = false;
  private payerAggregateLoading: boolean = false;
  private clientsLoading: boolean = false;
  private communityClientsLoading: boolean = false;
  private icprojectsLoading: boolean = false;
  private employmentByCommunityAggregateLoading: boolean = false;
  private totalIndigenousCommunityInvestmentLoading: boolean = false;
  private vendor: Vendor = _.cloneDeep(EmptyVendor);
  private vendorLoading: boolean = false;
  private expenseDiversityAggregateLoading: boolean = false;
  private equityReportMatrixLoading = false;
  private CCABReportMatrixLoading = false;
  private expensesLoading = false;
  private projectVendorsLoading = false;
  private projectExpensesLoading = false;
  private projectEmploymentLoading = false;
  private projectContributionsLoading = false;
  private unsdgExpensesLoading = false;
  private inclusionDevelopmentAgreementLoading = false;
  private icsLoading = false;

  constructor(reportType: ReportType = ReportType.Unknown) {
    this.reportType = reportType;
  }

  public setIsLoading(): void {
    this.isLoading =
      this.vendorLoading ||
      this.beneficiaryAggregateLoading ||
      this.payerAggregateLoading ||
      this.clientsLoading ||
      this.icprojectsLoading ||
      this.employmentByCommunityAggregateLoading ||
      this.totalIndigenousCommunityInvestmentLoading ||
      this.diversityReferenceDataLoading ||
      this.expenseDiversityAggregateLoading ||
      this.indigenousProcurementAggregateLoading ||
      this.summaryIndigenousProcurementAggregateLoading ||
      this.communityIndigenousProcurementAggregateLoading ||
      this.communityClientsLoading ||
      this.equityReportMatrixLoading ||
      this.CCABReportMatrixLoading ||
      this.expensesLoading ||
      this.payeeHelperLoading ||
      this.projectVendorsLoading ||
      this.projectExpensesLoading ||
      this.projectEmploymentLoading ||
      this.projectContributionsLoading ||
      this.icsLoading ||
      this.unsdgExpensesLoading ||
      this.inclusionDevelopmentAgreementLoading;
  }

  public populateDataLists(id: string, searchCriteria: GeneralSearchCriteria | null = null) {
    this.id = id;
    this.employmentByOccupationAggregate = [];
    if (searchCriteria) {
      this.searchCriteria = _.cloneDeep(searchCriteria);
    }
    // TODO: Need to only call this when necessary.
    this.getVendor(id);
    switch (this.reportType) {
      case ReportType.IndigenousImpactSummary:
        this.searchBeneficiaryAggregate();
        this.searchClients();
        this.searchIndigenousProcurementAggregate();
        this.searchSummaryIndigenousProcurementAggregate();
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.IndigenousImpactProject:
        this.searchBeneficiaryAggregate();
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.IndigenousImpactCommunity:
        this.searchIcProjectsAggregate();
        this.searchClients();
        this.searchCommunityIndigenousProcurementAggregate();
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.TangibleNetBenefitsProject:
        this.searchBeneficiaryAggregate();
        break;
      case ReportType.TangibleNetBenefitsCommunity:
        this.searchIcProjectsAggregate();
        // this.searchCommunityClients();
        this.searchClients();
        break;
      case ReportType.TangibleNetBenefitsCommunityClient:
      case ReportType.TangibleNetBenefitsSummary:
        this.searchBeneficiaryAggregate();
        this.searchClients();
        break;
      case ReportType.Insights:
        this.searchPayeeProcurementAggregate();
        this.searchBeneficiaryAggregate();
        this.searchClients();
        this.getDiversityReferenceData();
        break;
      case ReportType.InsightsProject:
        this.searchBeneficiaryAggregate();
        this.getDiversityReferenceData();
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.SocialImpactCorporate:
        this.searchClients();
        this.searchBeneficiaryAggregate();
        this.searchPayeeProcurementAggregate();
        this.getDiversityReferenceData();
        break;
      case ReportType.SocialImpactProject:
        this.getDiversityReferenceData();
        this.searchBeneficiaryAggregate();
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.SocialProcurementCorporate:
        this.getDiversityReferenceData();
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.SocialProcurementProject:
        this.getDiversityReferenceData();
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.SocialProcurementCommunityProject:
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.SocialProcurementCommunitySummary:
        this.searchIndigenousProcurementAggregate();
        this.searchSummaryIndigenousProcurementAggregate();
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.SocialProcurementCommunity:
        this.searchCommunityIndigenousProcurementAggregate();
        this.searchPayeeProcurementAggregate();
        break;
      case ReportType.SpecialityCanadianCouncil:
        this.searchClients();
        this.searchBeneficiaryAggregate();
        this.searchPayeeProcurementAggregate();
        this.getCCABReportMatrices();
        break;
      case ReportType.SpecialityEquityInclusionProject:
      case ReportType.SpecialityEquityInclusion:
      case ReportType.CompactEquityInclusion:
        this.getEquityInclusionReportMatrices();
        break;
      case ReportType.SocialImpactLocation:
        this.getExpenses();
        this.getDiversityReferenceData();
        break;
      case ReportType.PEHTAProject:
        this.getProjectVendors();
        this.getProjectExpenses();
        this.getProjectEmployment();
        this.getProjectContributions();
        this.getIndigenousCommunities();
        this.getDiversityReferenceData();
        break;
      case ReportType.PEHTACorporate:
        this.getPehtaVendors();
        this.getPehtaExpenses();
        this.getPehtaEmployment();
        this.getPehtaContributions();
        break;
      case ReportType.UNSustainableDevelopment:
        this.getUNSDGExpenses();
        break;
      case ReportType.InclusionDevelopmentAgreement:
        this.getInclusionDevelopmentAgreementData();
        break;
      default:
    }
    // Project Insights.
    if (this.reportType === ReportType.Unknown && (this.searchCriteria.projects as string) !== "") {
      this.getDiversityReferenceData();
    }
  }

  public handleError(error: any) {
    handleError(this.arrErrors, error);
  }

  public reportTypeStringToEnum(reportType: string): ReportType {
    switch (reportType) {
      case "social-impact-corporate":
        return ReportType.SocialImpactCorporate;
      case "social-impact-project":
        return ReportType.SocialImpactProject;
      case "social-impact-location":
        return ReportType.SocialImpactLocation;
      case "indigenous-impact-summary":
        return ReportType.IndigenousImpactSummary;
      case "indigenous-impact-project":
        return ReportType.IndigenousImpactProject;
      case "indigenous-impact-community":
        return ReportType.IndigenousImpactCommunity;
      case "social-procurement-corporate":
        return ReportType.SocialProcurementCorporate;
      case "social-procurement-project":
        return ReportType.SocialProcurementProject;
      case "social-procurement-community-summary":
        return ReportType.SocialProcurementCommunitySummary;
      case "social-procurement-community-project":
        return ReportType.SocialProcurementCommunityProject;
      case "social-procurement-community":
        return ReportType.SocialProcurementCommunity;
      case "diversity-in-employment-corporate":
        return ReportType.DiversityInEmploymentCorporate;
      case "diversity-in-employment-project":
        return ReportType.DiversityInEmploymentProject;
      case "tangible-net-benefits-summary":
        return ReportType.TangibleNetBenefitsSummary;
      case "tangible-net-benefits-project":
        return ReportType.TangibleNetBenefitsProject;
      case "tangible-net-benefits-community":
        return ReportType.TangibleNetBenefitsCommunity;
      case "tangible-net-benefits-community-client":
        return ReportType.TangibleNetBenefitsCommunityClient;
      case "speciality-canadian-council":
        return ReportType.SpecialityCanadianCouncil;
      case "speciality-bcorp-certified":
        return ReportType.SpecialityBCorpCertified;
      case "speciality-equity-inclusion":
        return ReportType.SpecialityEquityInclusion;
      case "speciality-equity-inclusion-project":
        return ReportType.SpecialityEquityInclusionProject;
      case "compact-equity-inclusion":
        return ReportType.CompactEquityInclusion;
      case "un-sustainable-development":
        return ReportType.UNSustainableDevelopment;
      case "dsc-project-indigenous-impact":
        return ReportType.DSCProjectIndigenousImpact;
      case "dsc-project-social-impact":
        return ReportType.DSCProjectSocialImpact;
      case "pehta-project":
        return ReportType.PEHTAProject;
      case "pehta-corporate":
        return ReportType.PEHTACorporate;
      case "inclusion-development-agreement":
        return ReportType.InclusionDevelopmentAgreement;
      default:
        return ReportType.Unknown;
    }
  }

  public reportTypeEnumToString(reportType: ReportType): string {
    switch (reportType) {
      case ReportType.SocialImpactCorporate:
        return "social-impact-corporate";
      case ReportType.SocialImpactProject:
        return "social-impact-project";
      case ReportType.SocialImpactLocation:
        return "social-impact-location";
      case ReportType.IndigenousImpactSummary:
        return "indigenous-impact-summary";
      case ReportType.IndigenousImpactProject:
        return "indigenous-impact-project";
      case ReportType.IndigenousImpactCommunity:
        return "indigenous-impact-community";
      case ReportType.SocialProcurementCorporate:
        return "social-procurement-corporate";
      case ReportType.SocialProcurementProject:
        return "social-procurement-project";
      case ReportType.SocialProcurementCommunitySummary:
        return "social-procurement-community-summary";
      case ReportType.SocialProcurementCommunityProject:
        return "social-procurement-community-project";
      case ReportType.SocialProcurementCommunity:
        return "social-procurement-community";
      case ReportType.DiversityInEmploymentCorporate:
        return "diversity-in-employment-corporate";
      case ReportType.DiversityInEmploymentProject:
        return "diversity-in-employment-project";
      case ReportType.TangibleNetBenefitsSummary:
        return "tangible-net-benefits-summary";
      case ReportType.TangibleNetBenefitsProject:
        return "tangible-net-benefits-project";
      case ReportType.TangibleNetBenefitsCommunity:
        return "tangible-net-benefits-community";
      case ReportType.TangibleNetBenefitsCommunityClient:
        return "tangible-net-benefits-community-client";
      case ReportType.SpecialityCanadianCouncil:
        return "speciality-canadian-council";
      case ReportType.SpecialityBCorpCertified:
        return "speciality-bcorp-certified";
      case ReportType.SpecialityEquityInclusion:
        return "speciality-equity-inclusion";
      case ReportType.SpecialityEquityInclusionProject:
        return "speciality-equity-inclusion-project";
      case ReportType.CompactEquityInclusion:
        return "compact-equity-inclusion";
      case ReportType.UNSustainableDevelopment:
        return "un-sustainable-development";
      case ReportType.DSCProjectIndigenousImpact:
        return "dsc-project-indigenous-impact";
      case ReportType.DSCProjectSocialImpact:
        return "dsc-project-social-impact";
      case ReportType.PEHTAProject:
        return "pehta-project";
      case ReportType.PEHTACorporate:
        return "pehta-corporate";
      case ReportType.InclusionDevelopmentAgreement:
        return "inclusion-development-agreement";
      default:
        return "";
    }
  }

  public getReportTitle(reportType: ReportType) {
    switch (reportType) {
      case ReportType.SocialImpactCorporate:
        return "Summary Social Impact Report";
      case ReportType.SocialImpactProject:
        return "Project Social Impact Report";
      case ReportType.SocialImpactLocation:
        return "Location Based Social Impact Report";
      case ReportType.IndigenousImpactSummary:
        return "Summary Indigenous Impact Report";
      case ReportType.IndigenousImpactProject:
        return "Project Indigenous Impact Report";
      case ReportType.IndigenousImpactCommunity:
        return "Community Indigenous Impact Report";
      case ReportType.SocialProcurementCorporate:
        return "Summary Social Procurement Report";
      case ReportType.SocialProcurementCommunitySummary:
        return "Summary Indigenous Procurement Report";
      case ReportType.SocialProcurementCommunityProject:
        return "Project Indigenous Procurement Report";
      case ReportType.SocialProcurementProject:
        return "Project Social Procurement Report";
      case ReportType.SocialProcurementCommunity:
        return "Community Indigenous Procurement Report";
      case ReportType.DiversityInEmploymentCorporate:
        return "Summary Employment Diversity Report";
      case ReportType.DiversityInEmploymentProject:
        return "Project Employment Diversity Report";
      case ReportType.TangibleNetBenefitsSummary:
        return "Summary Indigenous Benefit Report";
      case ReportType.TangibleNetBenefitsProject:
        return "Project Indigenous Benefit Report";
      case ReportType.TangibleNetBenefitsCommunity:
        return "Community Indigenous Benefit Report";
      case ReportType.TangibleNetBenefitsCommunityClient:
        return "Client Indigenous Benefit Report";
      case ReportType.SpecialityCanadianCouncil:
        return "Canadian Council for Aboriginal Business - Progressive Aboriginal Relations Report";
      case ReportType.SpecialityBCorpCertified:
        return "B-Corp Certified Report";
      case ReportType.SpecialityEquityInclusion:
        return "Summary Diversity and Equity Report";
      case ReportType.SpecialityEquityInclusionProject:
        return "Project Diversity and Equity Report";
      case ReportType.CompactEquityInclusion:
        return "Compact Diversity and Equity Report";
      case ReportType.UNSustainableDevelopment:
        return "United Nations Sustainable Development Goal Summary Report";
      case ReportType.DSCProjectIndigenousImpact:
        return "Deep Supply Chain - Project Indigenous Impact Report";
      case ReportType.DSCProjectSocialImpact:
        return "Deep Supply Chain - Project Social Impact Report";
      case ReportType.PEHTAProject:
        return "Pehta Project Benefit Statement";
      case ReportType.PEHTACorporate:
        return "Pehta Corporate Benefit Statement";
      case ReportType.InclusionDevelopmentAgreement:
        return "Enhanced Inclusion and Development Agreement Report";
      default:
        return "";
    }
  }

  public getReportFilenameTitle(reportType: ReportType) {
    switch (reportType) {
      case ReportType.PEHTAProject:
        return "PPBS";
      case ReportType.PEHTACorporate:
        return "PCBS";
      default:
        return this.getReportTitle(reportType);
    }
  }

  public showCoverPage() {
    switch (this.reportType) {
      default:
        return true;
    }
  }

  public getFilename(reportType: ReportType, searchCriteria: GeneralSearchCriteria | null = null, currentOrgName: string | null = null, pehtaSecure: boolean = false) {
    if (reportType === ReportType.PEHTAProject || reportType === ReportType.PEHTACorporate) {
      const community = searchCriteria ? ((searchCriteria.organizations as ShortRecord) ? (this.searchCriteria.organizations as ShortRecord).displayName : null) : null;
      const reportTitle = this.getReportFilenameTitle(reportType);
      const suffix = this.getFilenameSuffix(reportType, searchCriteria);
      const reportDate = searchCriteria ? (searchCriteria.dateTo ? searchCriteria.dateTo : this.getFilenameDate()) : this.getFilenameDate();

      const title = currentOrgName + " - " + reportTitle + " - " + (suffix !== "" ? suffix + " - " : "") + (community ? community : "ALL") + " - " + reportDate;

      if (reportType === ReportType.PEHTAProject) {
        return title + " - " + (pehtaSecure ? "general" : "sensitive") + ".pdf";
      }
      return title + ".pdf";
    } else {
      const reportTitle = this.getReportFilenameTitle(reportType);
      const suffix = this.getFilenameSuffix(reportType, searchCriteria);
      const reportDate = this.getFilenameDate();

      return reportTitle + " - " + (suffix !== "" ? suffix + " - " : "") + reportDate + ".pdf";
    }
  }

  public getDiversityReferenceData() {
    this.diversityReferenceDataLoading = true;
    getDiversityReferenceData()
      .then((response) => {
        this.diversityReferenceData = response;
        this.searchExpenseDiversityAggregate();
      })
      .catch((error) => {
        this.arrErrors.push(error);
      })
      .finally(() => {
        this.diversityReferenceDataLoading = false;
        this.setIsLoading();
      });
  }

  public getSupplyChainDiversityDescription(diversityMeta: DiversityDeclarationsMeta): string {
    const descriptionValues: string[] = [];
    if (diversityMeta) {
      for (const prop in diversityMeta) {
        if (typeof diversityMeta[prop] === "object") {
          const diversityDeclarationMeta: DiversityDeclarationMeta = diversityMeta[prop];
          const refDataCategories: DiversityRefDataCategory[] = this.getDiversityReferenceDataCategories(prop);
          for (const diversityValue of diversityDeclarationMeta.values) {
            const refDataCategoryItem: DiversityRefDataCategory | undefined = refDataCategories.find((x) => x.code === diversityValue);
            if (refDataCategoryItem !== undefined) {
              descriptionValues.push(refDataCategoryItem.itemNameForCategory);
            }
          }
        }
      }
    }
    return descriptionValues.join(", ");
  }

  public getDiversityReferenceDataCategories(categoryCode: string): DiversityRefDataCategory[] {
    const topLevelRefData: DiversityRefDataValue[] = this.diversityReferenceData
      ? (this.diversityReferenceData as DiversityReferenceData).refData.values.filter((i) => i.categories.find((c) => c.categoryCode === categoryCode))
      : [];
    const refData: DiversityRefDataCategory[] = [];
    for (const refDataValues of topLevelRefData) {
      const refDataCategories: DiversityRefDataCategory[] | undefined = refDataValues.categories.filter((x) => x.categoryCode === categoryCode);
      if (refDataCategories !== undefined) {
        for (const refDataCategory of refDataCategories) {
          refData.push(refDataCategory);
        }
      }
    }
    return refData;
  }

  public getVendor(id: string) {
    this.vendorLoading = true;
    getVendor(id)
      .then((response) => {
        this.vendor = response;
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.vendorLoading = false;
        this.setIsLoading();
      });
  }

  public isVisible(reportTypes: ReportType[]) {
    return reportTypes.includes(this.reportType);
  }

  public isProjectTypeReport(): boolean {
    switch (this.reportType) {
      case ReportType.SocialImpactProject:
      case ReportType.SocialProcurementProject:
      case ReportType.SocialProcurementCommunityProject:
      case ReportType.DiversityInEmploymentProject:
      case ReportType.TangibleNetBenefitsProject:
      case ReportType.IndigenousImpactProject:
      case ReportType.SpecialityEquityInclusionProject:
      case ReportType.PEHTAProject:
      case ReportType.InclusionDevelopmentAgreement:
        return true;
    }
    return false;
  }

  public isCommunityTypeReport(): boolean {
    switch (this.reportType) {
      case ReportType.TangibleNetBenefitsCommunity:
      case ReportType.SocialProcurementCommunity:
      case ReportType.IndigenousImpactCommunity:
        return true;
    }
    return false;
  }

  public isClientTypeReport(): boolean {
    switch (this.reportType) {
      case ReportType.TangibleNetBenefitsCommunityClient:
        return true;
    }
    return false;
  }

  public isCCABTypeReport(): boolean {
    if (this.reportType === ReportType.SpecialityCanadianCouncil) {
      return true;
    }
    return false;
  }

  public isLocationTypeReport(): boolean {
    switch (this.reportType) {
      case ReportType.SocialImpactLocation:
        return true;
    }
    return false;
  }

  public getLogoUrl(): string {
    let url: string = "";
    if (this.vendor) {
      if (this.vendor.organization) {
        if (this.vendor.organization.imageUrls) {
          url = this.vendor.organization.imageUrls.logo as string;
        }
      }
    }
    return url;
  }

  public getCommunity(searchCriteria: GeneralSearchCriteria): string | null {
    const selectedCommunity: ShortRecord = searchCriteria.organizations as ShortRecord;
    if (selectedCommunity !== undefined) {
      if (selectedCommunity.identifier) {
        return selectedCommunity.identifier;
      }
    }
    return null;
  }

  public getProject(searchCriteria: GeneralSearchCriteria): string | null {
    const selectedProject: ShortRecord = searchCriteria.projects as ShortRecord;
    if (selectedProject !== undefined) {
      if (selectedProject.identifier) {
        return selectedProject.identifier;
      }
    }
    return null;
  }

  public getClient(searchCriteria: GeneralSearchCriteria): string | null {
    const selectedClient: ShortRecord = searchCriteria.clients as ShortRecord;
    if (selectedClient !== undefined) {
      if (selectedClient.identifier) {
        return selectedClient.identifier;
      }
    }
    return null;
  }

  public getLocation(searchCriteria: GeneralSearchCriteria): string | null {
    const selectedLocation: ShortRecord = searchCriteria.locations as ShortRecord;
    if (selectedLocation !== undefined) {
      if (selectedLocation.identifier) {
        return selectedLocation.identifier;
      }
    }
    return null;
  }

  private getFilenameSuffix(reportType: ReportType, searchCriteria: GeneralSearchCriteria | null = null): string {
    const criteria = searchCriteria as GeneralSearchCriteria;
    let shortRecord: ShortRecord | null = null;
    if (this.isProjectTypeReport()) {
      shortRecord = criteria.projects as ShortRecord;
    } else if (this.isCommunityTypeReport()) {
      shortRecord = criteria.organizations as ShortRecord;
    }
    return shortRecord ? (shortRecord.displayName as string) : "";
  }

  private getFilenameDate(): string {
    return toIsoDateString(new Date().toLocaleDateString());
  }

  private searchIcProjectsAggregate(): any {
    this.icprojectsLoading = true;
    this.setIsLoading();
    const aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
    this.payeeSearchCriteria.payersOf = this.id;
    const selectedCommunity: string = this.getCommunity(this.searchCriteria) as string;
    searchAggregatesByRelation(this.searchCriteria, "icproject", selectedCommunity, undefined, aggSearchCriteria)
      .then((response) => {
        this.icprojectsAggregateResponse = response;
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.icprojectsLoading = false;
        this.setIsLoading();
      });
  }

  private searchBeneficiaryAggregate(): any {
    this.beneficiaryAggregateLoading = true;
    this.setIsLoading();
    const aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
    this.payeeSearchCriteria.payersOf = this.id;
    // Set the beneficiary agg search criteria.
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    let limitTo: string = "";
    if (selectedCommunity) {
      limitTo = selectedCommunity;
    }
    // Set the project agg search criteria.
    const selectedProject: string | null = this.getProject(this.searchCriteria);
    if (selectedProject) {
      aggSearchCriteria.hasProject = selectedProject;
    }
    // Set the client agg search criteria.
    const selectedClient: string | null = this.getClient(this.searchCriteria);
    if (selectedClient) {
      aggSearchCriteria.hasPayee = selectedClient;
    }
    searchAggregatesByRelation(this.searchCriteria, "relatedic", this.id, limitTo, aggSearchCriteria)
      .then((response) => {
        this.beneficiaryAggregateResponse = response;
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.beneficiaryAggregateLoading = false;
        this.setIsLoading();
      });
  }

  private searchPayerAggregate(): any {
    this.payerAggregateLoading = true;
    this.setIsLoading();
    searchAggregatesByRelation(this.searchCriteria, "client", this.id)
      .then((response) => {
        this.payerAggregateResponse = response;
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.payerAggregateLoading = false;
        this.setIsLoading();
      });
  }

  private getPayeesTotal(payees: DiversityTypePayee[], propertyName: string) {
    let total: number = 0;
    for (const payee of payees) {
      total += payee[propertyName] ? payee[propertyName] : 0;
    }
    return total;
  }

  private getEsgReportDataTable(totalsMeta: DiversityTotalsMeta, diversityClass: string, title: string, color: string): ReportDataTable {
    const hideTotalDiversityClasses = ["local", "sme"];
    const dataTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
    dataTable.parentsBold = true;
    dataTable.hideIfZeroTotal = true;
    dataTable.totalTitle = "";
    dataTable.total = _.cloneDeep(EmptyTotalsAggregateValue);
    dataTable.totalRow = _.cloneDeep(EmptyReportDataTableRow);
    dataTable.rows = [];
    dataTable.color = color;
    dataTable.headers = JSON.parse(JSON.stringify(this.diversityBreakdownHeaders)); // We are cloning the headers so we can conditionally modify them

    const diversityTotalsType: DiversityTotalsType | undefined = totalsMeta.esgByClass ? totalsMeta.esgByClass.find((v) => v.diversityClass === diversityClass) : undefined;

    let overallGoodsTotal: number = 0;
    let overallServicesTotal: number = 0;
    let overallEquipmentTotal: number = 0;
    if (diversityTotalsType !== undefined) {
      dataTable.headers[0].text = title;

      for (const diversityTypeItem of diversityTotalsType.diversityTypes) {
        const diversityName = getDiversityNameFromCode(this.diversityReferenceData as DiversityReferenceData, diversityTypeItem.diversityType, diversityClass);

        // Main rows.
        const row: ReportDataTableRow = _.cloneDeep(EmptyReportDataTableRow);
        // Type
        row.data.push(diversityName);

        // Goods
        const goodsTotal = this.getPayeesTotal(diversityTypeItem.payees, "goods");
        overallGoodsTotal += goodsTotal;
        row.data.push(convertToTotalsAggregateValue(goodsTotal));

        // Services
        const servicesTotal = this.getPayeesTotal(diversityTypeItem.payees, "services");
        overallServicesTotal += servicesTotal;
        row.data.push(convertToTotalsAggregateValue(servicesTotal));

        // Equipment
        const equipmentTotal = this.getPayeesTotal(diversityTypeItem.payees, "equipment");
        overallEquipmentTotal += equipmentTotal;
        row.data.push(convertToTotalsAggregateValue(equipmentTotal));

        // Total
        const overallTotal = goodsTotal + servicesTotal + equipmentTotal;
        row.data.push(convertToTotalsAggregateValue(overallTotal));

        // Children Payees.
        diversityTypeItem.payees.sort((a, b) => (a.payeeName < b.payeeName ? -1 : 1));
        for (const payee of diversityTypeItem.payees) {
          const childRow: ReportDataTableRow = _.cloneDeep(EmptyReportDataTableRow);
          childRow.data.push(payee.payeeName);
          childRow.data.push(convertToTotalsAggregateValue(payee.goods));
          childRow.data.push(convertToTotalsAggregateValue(payee.services));
          childRow.data.push(convertToTotalsAggregateValue(payee.equipment));
          childRow.data.push(convertToTotalsAggregateValue(payee.goods + payee.services + payee.equipment));
          childRow.columnStyle = [{ index: 0, indentations: 2 }];
          row.children.push(childRow);
        }

        // If sme or local flatten list by hiding diversity type row. (sme and local do not have diversity types)
        if (hideTotalDiversityClasses.indexOf(diversityTotalsType.diversityClass) > -1) {
          row.data = [];
        }

        // Add the main row and children to the table.
        row.columnStyle = [{ index: 0, indentations: 1 }];
        dataTable.rows.push(row);

        // Overall total.
        dataTable.total = convertToTotalsAggregateValue(overallGoodsTotal + overallServicesTotal + overallEquipmentTotal);
      }
    }
    // Total row.
    const totalRow: ReportDataTableRow = _.cloneDeep(EmptyReportDataTableRow);
    totalRow.data.push(convertToTotalsAggregateValue(overallGoodsTotal));
    totalRow.data.push(convertToTotalsAggregateValue(overallServicesTotal));
    totalRow.data.push(convertToTotalsAggregateValue(overallEquipmentTotal));
    totalRow.data.push(convertToTotalsAggregateValue(overallGoodsTotal + overallServicesTotal + overallEquipmentTotal));
    dataTable.totalRow = totalRow;
    return dataTable;
  }

  private searchExpenseDiversityAggregate(): any {
    this.expenseDiversityAggregateLoading = true;
    this.setIsLoading();
    searchExpenseDiversity(this.searchCriteria, this.id)
      .then((response) => {
        this.expenseDiversityAggregateResponse = response;
        // Add the data tables.
        const totalsMeta: DiversityTotalsMeta = ((this.expenseDiversityAggregateResponse as ResourceSearchResult).searchResults.results as Aggregate[])[0].totals as DiversityTotalsMeta;
        this.expenseDiversityReportDataTables = [];
        this.expenseDiversityReportDataTables.push(this.getEsgReportDataTable(totalsMeta, "impactESG", "Third Party Certified Equity Seeking Businesses", colors.colorMediumBlue));
        this.expenseDiversityReportDataTables.push(this.getEsgReportDataTable(totalsMeta, "socialFocusedOrgs", "Self Declared Equity Seeking Businesses", colors.colorMediumBlue));
        this.expenseDiversityReportDataTables.push(this.getEsgReportDataTable(totalsMeta, "impactSocial", "Social Purpose Organizations", colors.colorMediumBlue));
        this.expenseDiversityReportDataTables.push(this.getEsgReportDataTable(totalsMeta, "local", "Local Spend", colors.colorLocal));
        this.expenseDiversityReportDataTables.push(this.getEsgReportDataTable(totalsMeta, "sme", "Small Enterprise Spend", colors.colorSmallEnterprise));
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.expenseDiversityAggregateLoading = false;
        this.setIsLoading();
      });
  }

  private searchCommunityIndigenousProcurementAggregate(): any {
    this.communityIndigenousProcurementAggregateLoading = true;
    this.setIsLoading();
    const aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
    // Set the beneficiary search criteria.
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    if (selectedCommunity) {
      this.searchCriteria.beneficiaries = selectedCommunity;
    }
    searchAggregateProcurementByType(this.searchCriteria, "project")
      .then((response) => {
        this.communityIndigenousProcurementAggregateResponse = response;
        const procurementProjects: ProcurementProjectsMeta = ((this.communityIndigenousProcurementAggregateResponse as ResourceSearchResult).searchResults.results[0] as Aggregate).totals as ProcurementProjectsMeta;
        this.communityReportDataTables = [];
        if (procurementProjects.icProjects !== undefined) {
          const dataTable1: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
          dataTable1.totalRow = null;
          dataTable1.pageBreakAfter = true;
          dataTable1.headers = this.indigneousProcurementSummaryHeaders;
          for (const icProject of procurementProjects.icProjects) {
            let totalAmount: number = 0;
            const projectData: ReportDataTableRow[] = [];
            for (const project of icProject.projects) {
              projectData.push({ data: [project.displayName, formatCurrencyToString(project.amount.toString())], children: [], columnStyle: [{ index: 0, indentations: 1 }] });
              totalAmount += project.amount;
            }
            const communityData: string[] = [icProject.displayName, formatCurrencyToString(totalAmount.toString())];
            dataTable1.rows.push({ data: communityData, children: projectData, columnStyle: [{ index: 0, fontSize: "11pt" }] });
          }
          this.communityReportDataTables.push(dataTable1);
        }
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.communityIndigenousProcurementAggregateLoading = false;
        this.setIsLoading();
      });
  }

  private searchSummaryIndigenousProcurementAggregate(): any {
    this.summaryIndigenousProcurementAggregateLoading = true;
    this.setIsLoading();

    searchAggregateProcurementByType(this.searchCriteria, "payee")
      .then((response) => {
        this.summaryIndigenousProcurementAggregateResponse = response;
        const procurementPayees: ProcurementPayeesMeta = ((this.summaryIndigenousProcurementAggregateResponse as ResourceSearchResult).searchResults.results[0] as Aggregate).totals as ProcurementPayeesMeta;
        if (procurementPayees.icSpendByPayee !== undefined) {
          // Community data tables.
          this.summaryReportDataTables = [];
          const dataTable1: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
          dataTable1.totalRow = null;
          dataTable1.pageBreakAfter = true;
          dataTable1.headers = this.indigneousProcurementSummaryHeaders;
          const communityRows: ReportDataTableRow[] = [];
          let totalCommunityAmount: number = 0;
          // Sort by Indigenous Community.
          const sortedResults = sortResultsAlphabetically(procurementPayees.icSpendByPayee);
          for (const icSpendByPayee of sortedResults) {
            if (icSpendByPayee.beneficiary) {
              communityRows.push({ data: [icSpendByPayee.displayName, formatCurrencyToString(icSpendByPayee.total.toString())], children: [], columnStyle: [{ index: 0, indentations: 1 }] } as ReportDataTableRow);
              totalCommunityAmount += icSpendByPayee.total;
            }
          }
          dataTable1.rows.push({ data: ["Indigenous Community", formatCurrencyToString(totalCommunityAmount.toString())], children: communityRows } as ReportDataTableRow);
          this.summaryReportDataTables.push(dataTable1);
        }
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.summaryIndigenousProcurementAggregateLoading = false;
        this.setIsLoading();
      });
  }

  private searchPayeeProcurementAggregate(): any {
    this.indigenousPayeeProcurementAggregateLoading = true;
    this.setIsLoading();

    const aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
    // Set the project search criteria.
    const selectedProject: string | null = this.getProject(this.searchCriteria);
    if (selectedProject) {
      this.searchCriteria.projects = selectedProject;
    }
    // Set the beneficiary search criteria - currently this has no effect.
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    if (selectedCommunity) {
      this.searchCriteria.beneficiaries = selectedCommunity;
    }

    searchAggregateProcurementByType(this.searchCriteria, "payee")
      .then((response) => {
        this.indigenousPayeeProcurementAggregateResponse = response;
        const procurementPayees: ProcurementPayeesMeta = ((this.indigenousPayeeProcurementAggregateResponse as ResourceSearchResult).searchResults.results[0] as Aggregate).totals as ProcurementPayeesMeta;

        if (procurementPayees.icSpendByPayee !== undefined) {
          // Community data tables.
          this.payeeCommunityReportDataTables = [];
          this.payeeCommunityReportFlattenedDataTables = [];

          const flattenedData: any = {
            indigenousImpact: {
              displayName: "Certified Indigenous Owned Business",
              total: 0,
              communities: {},
            },
            indigenousFocused: {
              displayName: "Self Declared Indigenous Owned Business",
              total: 0,
              communities: {},
            },
            indigenousOwned: {
              displayName: "Indigenous Community Owned Business",
              total: 0,
              communities: {},
            },
            indigenousPartner: {
              displayName: "Indigenous Partner",
              total: 0,
              communities: {},
            },
          };

          // Sort the list by Indigenous Community.
          const sortedResults = sortResultsAlphabetically(procurementPayees.icSpendByPayee);
          for (const icSpendByPayee of sortedResults) {
            const dataTable1: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
            dataTable1.totalRow = null;
            dataTable1.pageBreakAfter = true;
            dataTable1.headers = this.indigneousProcurementSummaryProjectHeaders;
            dataTable1.className = "margin-bottom";
            if (icSpendByPayee.beneficiary && (!selectedCommunity || icSpendByPayee.beneficiary === selectedCommunity)) {
              dataTable1.rows.push({
                data: [icSpendByPayee.displayName, "", formatCurrencyToString(icSpendByPayee.total.toString())],
                columnStyle: [
                  { index: 0, fontSize: "11pt" },
                  { index: 2, fontSize: "11pt" },
                ],
              } as ReportDataTableRow);
              this.addBeneficiaryEsgToDataTable("indigenousImpact", "Certified Indigenous Owned Business", dataTable1, icSpendByPayee);
              this.addBeneficiaryEsgToDataTable("indigenousFocused", "Self Declared Indigenous Owned Business", dataTable1, icSpendByPayee);
              this.addBeneficiaryEsgToDataTable("indigenousOwned", "Indigenous Community Owned Business", dataTable1, icSpendByPayee);
              this.addBeneficiaryEsgToDataTable("indigenousPartner", "Indigenous Partner", dataTable1, icSpendByPayee);
              this.payeeCommunityReportDataTables.push(dataTable1);

              // Flatten data
              for (const businessType of icSpendByPayee.indigenousBusinessTypes) {
                if (Object.keys(flattenedData).find((entry) => entry === businessType.type)) {
                  flattenedData[businessType.type].total += businessType.total;

                  for (const payee of businessType.payees) {
                    if (flattenedData[businessType.type].communities[icSpendByPayee.displayName] === undefined) {
                      flattenedData[businessType.type].communities[icSpendByPayee.displayName] = {
                        total: payee.amount,
                        payees: {},
                      };
                    } else {
                      flattenedData[businessType.type].communities[icSpendByPayee.displayName].total += payee.amount;
                    }
                    flattenedData[businessType.type].communities[icSpendByPayee.displayName].payees[payee.displayName] = payee.amount;
                  }
                }
              }
            }
          }

          // Build tables from flattened data
          for (const businessType of Object.keys(flattenedData)) {
            if (flattenedData[businessType].total > 0) {
              const dataTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
              dataTable.pageBreakAfter = true;
              dataTable.headers = this.indigneousProcurementSummaryProjectHeaders;
              dataTable.className = "margin-bottom";
              dataTable.headers = JSON.parse(JSON.stringify(this.diversityVendorBreakdownHeaders)); // We are cloning the headers so we can conditionally modify them
              dataTable.headers[0].text = flattenedData[businessType].displayName;

              const communities = Object.keys(flattenedData[businessType].communities).sort((a, b) => (a < b ? -1 : 1));
              for (const community of communities) {
                const communityData = flattenedData[businessType].communities[community];
                const rows: ReportDataTableRow[] = [];

                for (const payee of Object.keys(flattenedData[businessType].communities[community].payees)) {
                  const payeeData = flattenedData[businessType].communities[community].payees[payee] as number;
                  rows.push({ data: [payee, formatCurrencyToString(payeeData.toString())], children: [], columnStyle: [{ index: 0, indentations: 2 }] } as ReportDataTableRow);
                }
                dataTable.rows.push({ data: [community, formatCurrencyToString(communityData.total.toString())], children: rows, columnStyle: [{ index: 0, indentations: 1 }] } as ReportDataTableRow);
              }
              // Totals row
              const totalRow: ReportDataTableRow = _.cloneDeep(EmptyReportDataTableRow);
              totalRow.data.push(formatCurrencyToString(flattenedData[businessType].total.toString()));
              dataTable.totalRow = totalRow;

              this.payeeCommunityReportFlattenedDataTables.push(dataTable);
            }
          }
        }
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.indigenousPayeeProcurementAggregateLoading = false;
        this.setIsLoading();
      });
  }

  private searchIndigenousProcurementAggregate(): any {
    this.indigenousProcurementAggregateLoading = true;
    this.setIsLoading();

    const aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
    // Set the project agg search criteria.
    const selectedProject: string | null = this.getProject(this.searchCriteria);
    if (selectedProject) {
      this.searchCriteria.projects = selectedProject;
    }
    searchAggregateProcurementByType(this.searchCriteria, "indigenous")
      .then((response) => {
        this.indigenousProcurementAggregateResponse = response;
        const procurementTypes: ProcurementTypeMeta = ((this.indigenousProcurementAggregateResponse as ResourceSearchResult).searchResults.results[0] as Aggregate).totals as ProcurementTypeMeta;

        if (procurementTypes.icSpendByType !== undefined) {
          // Community data tables.
          this.indigenousTypeReportDataTables = [];

          // Diversity tables - 1 big table for these.
          const dataTable2: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
          dataTable2.totalRow = null;
          dataTable2.pageBreakAfter = true;
          dataTable2.headers = this.indigneousProcurementSummaryProjectHeaders;
          dataTable2.className = "margin-bottom";
          for (const icSpendByType of procurementTypes.icSpendByType) {
            this.addEsgToDataTable("certifiedIndigenousOwned", "Certified Indigenous Owned Business", dataTable2, icSpendByType);
            this.addEsgToDataTable("selfDeclaredIndigenousOwned", "Self Declared Indigenous Owned Business", dataTable2, icSpendByType);
            this.addEsgToDataTable("indigenousOwned", "Indigenous Community Owned Business", dataTable2, icSpendByType);
            this.addEsgToDataTable("indigenousPartner", "Indigenous Partner", dataTable2, icSpendByType);
          }
          this.indigenousTypeReportDataTables.push(dataTable2);
        }
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.indigenousProcurementAggregateLoading = false;
        this.setIsLoading();
      });
  }

  private addBeneficiaryEsgToDataTable(spendType: string, spendTypeDescription: string, dataTable: ReportDataTable, payeesType: ProcurementBeneficiaryPayeesType) {
    const rows: ReportDataTableRow[] = [];
    // console.log(spendType);
    for (const businessType of payeesType.indigenousBusinessTypes) {
      if (businessType.type === spendType) {
        // Sort by Payee.
        const sortedResults = sortResultsAlphabetically(businessType.payees);
        for (const payee of sortedResults) {
          rows.push({ data: [payee.displayName, "", formatCurrencyToString(payee.amount.toString())], children: [], columnStyle: [{ index: 0, indentations: 2 }] } as ReportDataTableRow);
        }
        dataTable.rows.push({ data: [spendTypeDescription, "", formatCurrencyToString(businessType.total.toString())], children: rows, columnStyle: [{ index: 0, indentations: 1 }] } as ReportDataTableRow);
      }
    }
  }

  private addEsgToDataTable(spendType: string, spendTypeDescription: string, dataTable: ReportDataTable, payeesType: ProcurementTypePayeesType) {
    const rows: ReportDataTableRow[] = [];
    if (payeesType.type === spendType) {
      // Sort by Payee.
      const sortedResults = sortResultsAlphabetically(payeesType.payees);
      for (const payee of sortedResults) {
        rows.push({ data: [payee.displayName, "", formatCurrencyToString(payee.amount.toString())], children: [], columnStyle: [{ index: 0, indentations: 1 }] } as ReportDataTableRow);
      }
      dataTable.rows.push({ data: [spendTypeDescription, "", formatCurrencyToString(payeesType.total.toString())], children: rows, columnStyle: [{ index: 0, fontSize: "11pt" }] } as ReportDataTableRow);
    }
  }

  private searchCommunityClients(): any {
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    if (selectedCommunity) {
      this.communityClientsLoading = true;
      this.setIsLoading();
      getIndigenousCommunityClients(selectedCommunity, true)
        .then((response) => {
          this.communityClientsResponse = response;
        })
        .catch((error) => {
          handleError(this.arrErrors, error);
        })
        .finally(() => {
          this.communityClientsLoading = false;
          this.setIsLoading();
        });
    }
  }

  private searchClients(): any {
    this.clientsLoading = true;
    this.setIsLoading();
    const aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
    this.payeeSearchCriteria.payersOf = this.id;
    // Set the beneficiary agg search criteria.
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    if (selectedCommunity) {
      aggSearchCriteria.hasBeneficiary = selectedCommunity;
    }
    // Set the project agg search criteria.
    const selectedProject: string | null = this.getProject(this.searchCriteria);
    if (selectedProject) {
      aggSearchCriteria.hasProject = selectedProject;
    }
    // Set the client agg search criteria.
    const selectedClient: string | null = this.getClient(this.searchCriteria);
    if (selectedClient) {
      aggSearchCriteria.hasPayee = selectedClient;
    }
    aggSearchCriteria.isClient = true;
    searchPayees(mapSearchParams({ sortBy: "common.displayName", descending: false, page: 1, rowsPerPage: 999999 }, "", []), this.searchCriteria, aggSearchCriteria, this.payeeSearchCriteria, false)
      .then((response) => {
        this.clientsResponse = response;
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.clientsLoading = false;
        this.setIsLoading();
      });
  }

  private searchEmploymentByCommunityAggregate(): any {
    this.employmentByCommunityAggregateLoading = true;
    this.setIsLoading();
    searchAggregatesByRelation(this.searchCriteria, "relatedic", this.id)
      .then((response) => {
        this.employmentByCommunityAggregateResponse = response;
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.employmentByCommunityAggregateLoading = false;
        this.setIsLoading();
      });
  }

  private searchIndigenousCommunityInvestmentAggregate(): any {
    const drillPath: string = "community-investment";
    this.totalIndigenousCommunityInvestmentLoading = true;
    this.setIsLoading();
    searchAggregateDrill(this.searchCriteria, drillPath)
      .then((response) => {
        this.communityInvestmentTreeMap.data = [];
        this.communityInvestmentReportItems = [];
        let totalItemsToProcess: number = 0;
        let totalAmount: number = 0;
        if ((response as ResourceSearchResult).searchResults.results.length === 0) {
          this.totalIndigenousCommunityInvestmentLoading = false;
          this.setIsLoading();
        }
        for (const result of (response as ResourceSearchResult).searchResults.results) {
          const parent: DrilldownSeriesTypeBenefitMeta = (result as AggregateSeriesDrilldown).item as DrilldownSeriesTypeBenefitMeta;
          totalAmount += parent.total as number;
          const newDrillPath = drillPath + "/" + parent.code;
          searchAggregateDrill(this.searchCriteria, newDrillPath)
            .then((drillDownResponse) => {
              totalItemsToProcess += (drillDownResponse as ResourceSearchResult).searchResults.resultMeta.matched;
              for (const resultParent of (drillDownResponse as ResourceSearchResult).searchResults.results) {
                const drillDownInvestment: DrilldownSeriesTypeBenefitMeta = (resultParent as AggregateSeriesDrilldown).item as DrilldownSeriesTypeBenefitMeta;
                // Add parent to the list (if there's not already one from a different investment type)
                if (!this.communityInvestmentTreeMap.data.find((item) => item.name === drillDownInvestment.name)) {
                  this.communityInvestmentTreeMap.data.push({
                    name: drillDownInvestment.name,
                    id: drillDownInvestment.code,
                    color: colors.colors[this.communityInvestmentTreeMap.data.length],
                  } as CommunityInvestmentTreeMapParent);
                }
                // If there is already a report item the same then add to it.
                const existingParent: NamedAggregateValue = this.communityInvestmentReportItems.find((item) => item.name === drillDownInvestment.name) as NamedAggregateValue;
                if (existingParent) {
                  existingParent.value.value = (drillDownInvestment.total as number) + existingParent.value.value;
                } else {
                  this.communityInvestmentReportItems.push({ name: drillDownInvestment.name as string, value: { value: drillDownInvestment.total as number, valueFormatted: "", percentage: 0, percentageFormatted: "" } });
                }
                // Iterate the parents and add the children.
                const newChildrenDrillPath = newDrillPath + "/" + drillDownInvestment.code;
                searchAggregateDrill(this.searchCriteria, newChildrenDrillPath)
                  .then((drillDownResponseChildren) => {
                    for (const resultChild of (drillDownResponseChildren as ResourceSearchResult).searchResults.results) {
                      const drillDownInvestmentChild: DrilldownSeriesTypeBenefitMeta = (resultChild as AggregateSeriesDrilldown).item as DrilldownSeriesTypeBenefitMeta;
                      // If there is already a child with the same parent and name then combine the values together.
                      const existingChild: CommunityInvestmentTreeMapChild = this.communityInvestmentTreeMap.data.find(
                        (item) => item.name === drillDownInvestmentChild.name && (item as CommunityInvestmentTreeMapChild).parent === drillDownInvestment.code,
                      ) as CommunityInvestmentTreeMapChild;
                      if (existingChild) {
                        existingChild.value = (drillDownInvestmentChild.total as number) + existingChild.value;
                      } else {
                        this.communityInvestmentTreeMap.data.push({ name: drillDownInvestmentChild.name, parent: drillDownInvestment.code, value: drillDownInvestmentChild.total } as CommunityInvestmentTreeMapChild);
                      }
                    }
                  })
                  .catch((error) => {
                    handleError(this.arrErrors, error);
                  })
                  .finally(() => {
                    totalItemsToProcess--;
                    if (totalItemsToProcess === 0) {
                      this.communityInvestmentTotal = convertToTotalsAggregateValue(totalAmount);
                      // Calculate the report item percentages at the end once we know the totalAmount.
                      for (const item of this.communityInvestmentReportItems) {
                        item.value = convertToTotalsAggregateValue(item.value.value, totalAmount);
                      }
                      this.totalIndigenousCommunityInvestmentLoading = false;
                      this.setIsLoading();
                    }
                  });
              }
            })
            .catch((error) => {
              handleError(this.arrErrors, error);
            });
        }
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      });
  }

  private async getExpenses(): Promise<void> {
    this.expensesLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const expenseQuery: DataServiceAggregateQuery = {
      groupBy: "payment.hasProject",
      dateFrom: this.searchCriteria.dateFrom,
      dateTo: this.searchCriteria.dateTo,
      constraints: {},
    };
    if (this.searchCriteria.locations) {
      expenseQuery.constraints["properties.location"] = (this.searchCriteria.locations as ShortRecord).identifier as string;
    }

    const expenseAgg = await getExpenseAggregates(expenseQuery, org);
    this.expenseData = expenseAgg;

    const projects: string[] = [];
    const payees: string[] = [];

    const projectExpenseQuery = _.cloneDeep(expenseQuery);
    projectExpenseQuery.groupBy = "payment.hasPayee";

    for (const projectAgg of expenseAgg) {
      const projectIdentifier = projectAgg.identifier;
      projects.push(projectIdentifier);
      projectExpenseQuery.constraints["payment.hasProject"] = projectIdentifier;

      const data = await getExpenseAggregates(projectExpenseQuery, org);
      this.projectExpenseData[projectIdentifier] = data;
      for (const agg of data) {
        if (payees.indexOf(agg.identifier) === -1) {
          payees.push(agg.identifier);
        }
      }

      const servicesExpenseQuery = _.cloneDeep(projectExpenseQuery);
      servicesExpenseQuery.constraints["common.recordType"] = "Services";
      const services = getExpenseAggregates(servicesExpenseQuery, org).then((response) => {
        this.projectServicesExpenseData[projectIdentifier] = response;
      });

      const goodsExpenseQuery = _.cloneDeep(projectExpenseQuery);
      goodsExpenseQuery.constraints["common.recordType"] = "Goods";
      const goods = getExpenseAggregates(goodsExpenseQuery, org).then((response) => {
        this.projectGoodsExpenseData[projectIdentifier] = response;
      });

      const equipmentExpenseQuery = _.cloneDeep(projectExpenseQuery);
      equipmentExpenseQuery.constraints["common.recordType"] = "Equipment";
      const equipment = getExpenseAggregates(equipmentExpenseQuery, org).then((response) => {
        this.projectEquipmentExpenseData[projectIdentifier] = response;
      });

      const contributionExpenseQuery = _.cloneDeep(projectExpenseQuery);
      contributionExpenseQuery.constraints["common.recordType"] = "Contribution";
      const contribution = getExpenseAggregates(contributionExpenseQuery, org).then((response) => {
        this.projectContributionExpenseData[projectIdentifier] = response;
      });

      const revenueExpenseQuery = _.cloneDeep(expenseQuery);
      revenueExpenseQuery.constraints["payment.hasProject"] = projectIdentifier;
      revenueExpenseQuery.constraints["payment.hasPayee"] = org;
      delete revenueExpenseQuery.constraints["properties.location"];
      const revenue = getExpenseAggregates(revenueExpenseQuery, org).then((response) => {
        this.projectRevenueExpenseData[projectIdentifier] = response;
      });

      await Promise.all([goods, equipment, services, contribution, revenue]).catch((error) => {
        handleError(this.arrErrors, error);
      });
    }

    const overheadExpenseQuery = _.cloneDeep(expenseQuery);
    overheadExpenseQuery.groupBy = "payment.hasPayee";
    overheadExpenseQuery.constraints["common.recordType"] = "GeneralExpense";
    const overheads = getExpenseAggregates(overheadExpenseQuery, org).then((response) => {
      this.overheadExpenseData = response;
    });

    const overheadServicesQuery = _.cloneDeep(overheadExpenseQuery);
    overheadServicesQuery.constraints["expense.expenseClass"] = "services";
    const oServices = getExpenseAggregates(overheadServicesQuery, org).then((response) => {
      this.overheadServicesExpenseData = response;
      for (const agg of response) {
        if (payees.indexOf(agg.identifier) === -1) {
          payees.push(agg.identifier);
        }
      }
    });

    const overheadGoodsQuery = _.cloneDeep(overheadExpenseQuery);
    overheadGoodsQuery.constraints["expense.expenseClass"] = "goods";
    const oGoods = getExpenseAggregates(overheadGoodsQuery, org).then((response) => {
      this.overheadGoodsExpenseData = response;
      for (const agg of response) {
        if (payees.indexOf(agg.identifier) === -1) {
          payees.push(agg.identifier);
        }
      }
    });

    const overheadEquipementQuery = _.cloneDeep(overheadExpenseQuery);
    overheadEquipementQuery.constraints["expense.expenseClass"] = "equipment";
    const oEquipment = getExpenseAggregates(overheadEquipementQuery, org).then((response) => {
      this.overheadEquipmentExpenseData = response;
      for (const agg of response) {
        if (payees.indexOf(agg.identifier) === -1) {
          payees.push(agg.identifier);
        }
      }
    });

    const overheadContributionsQuery = _.cloneDeep(overheadExpenseQuery);
    overheadContributionsQuery.constraints["expense.expenseClass"] = "contribution";
    const oContributions = getExpenseAggregates(overheadContributionsQuery, org).then((response) => {
      this.overheadContributionsExpenseData = response;
      for (const agg of response) {
        if (payees.indexOf(agg.identifier) === -1) {
          payees.push(agg.identifier);
        }
      }
    });

    await Promise.all([oGoods, oEquipment, oServices, oContributions, overheads]).catch((error) => {
      handleError(this.arrErrors, error);
    });

    await this.getProjectDetails(projects);
    await this.getPayeeDetails(payees);

    this.expensesLoading = false;
    this.setIsLoading();
  }

  private async getProjectDetails(projects: string[]): Promise<void> {
    const chunkSize = 12;
    for (let i = 0; i < projects.length; i += chunkSize) {
      const chunk = projects.slice(i, i + chunkSize);
      const requests: Array<Promise<void | Project>> = [];

      for (const project of chunk) {
        if (project) {
          requests.push(
            getProject(project, true).then((response) => {
              this.projectDetails[project] = response;
            }),
          );
        }
      }

      await Promise.all(requests).catch((error) => {
        handleError(this.arrErrors, error);
      });
    }
  }

  private async getPayeeDetails(payees: string[]): Promise<void> {
    const filteredPayees = payees.filter((payee) => payee !== undefined && payee !== null && payee !== "");

    const searchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    const searchParams = _.cloneDeep(EmptySearchParams);
    const aggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
    const payeeSearchCriteria = _.cloneDeep(EmptyPayeeSearchCriteria);
    searchParams.ipp = "10000";

    const chunkSize = 15;
    for (let i = 0; i < filteredPayees.length; i += chunkSize) {
      const chunk = filteredPayees.slice(i, i + chunkSize);
      const data = await searchPayees(searchParams, searchCriteria, aggSearchCriteria, payeeSearchCriteria, false, [["common.identifier", chunk]]);
      for (const result of data.searchResults.results as Vendor[]) {
        if (result.common) {
          if (result.common.identifier) {
            this.payeeDetails[result.common.identifier] = result;
          }
        }
      }
    }
  }

  private async getEquityInclusionReportMatrices(): Promise<void> {
    this.equityReportMatrixLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const tenant = org.split(":").pop() as string;

    const selectedProject: string | null = this.getProject(this.searchCriteria);

    const fields = [
      {
        col: "$diversityDetails.diversityDeclaration.educationDegree",
        field: "education",
      },
      {
        col: "$diversityDetails.diversityDeclaration.gender",
        field: "gender",
      },
      {
        col: "$diversityDetails.diversityDeclaration.sexualOrientation",
        field: "orientation",
      },
      {
        col: "$diversityDetails.diversityDeclaration.heritage",
        field: "ethnicity",
      },
      {
        col: "$diversityDetails.diversityDeclaration.indigenousPeoples",
        field: "indigenous",
      },
      {
        col: "$diversityDetails.diversityDeclaration.immigrationStatusAuthorized",
        field: "immigration",
      },
      {
        col: "$diversityDetails.diversityDeclaration.veteran",
        field: "veteran",
      },
      {
        col: "$diversityDetails.diversityDeclaration.disabilities",
        field: "disability",
      },
      {
        col: "$diversityDetails.diversityDeclaration.barriers",
        field: "barriers",
      },
      {
        col: "$diversityDetails.diversityDeclaration.ageRange",
        field: "age",
      },
      {
        col: "$diversityDetails.diversityDeclaration.faithReligion",
        field: "religion",
      },
      {
        col: "$diversityDetails.diversityDeclaration.refugee",
        field: "refugee",
      },
    ];

    const chunks: Array<Array<{ col: string; field: string }>> = [];
    while (fields.length > 0) {
      chunks.push(fields.splice(0, 2));
    }

    for (const fieldChunk of chunks) {
      const endpointCalls = [
        this.getLevelMatrix(tenant, org, fieldChunk, selectedProject),
        this.getLocationMatrix(tenant, org, fieldChunk, selectedProject),
        this.getOccupationMatrix(tenant, org, fieldChunk, selectedProject),
        this.getTotalMatrix(tenant, org, fieldChunk, selectedProject),
      ];

      await Promise.all(endpointCalls);
    }

    const headcountQuery: DataServiceAggregateQuery = {
      dateFrom: this.searchCriteria.dateFrom,
      dateTo: this.searchCriteria.dateTo,
      constraints: {},
    };

    const headcount = await getEmploymentHeadcount(headcountQuery, org);
    this.EIRHeadcountData = headcount;

    this.equityReportMatrixLoading = false;
    this.setIsLoading();
  }

  private async getCCABReportMatrices() {
    this.CCABReportMatrixLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const tenant = org.split(":").pop() as string;

    const currentYear = new Date().getFullYear();
    const years = [currentYear - 1, currentYear - 2, currentYear - 3];
    const searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);

    this.CCABYearlyDiversityData[currentYear - 1] = { level: _.cloneDeep(EmptyData), location: _.cloneDeep(EmptyData) };
    this.CCABYearlyDiversityData[currentYear - 2] = { level: _.cloneDeep(EmptyData), location: _.cloneDeep(EmptyData) };
    this.CCABYearlyDiversityData[currentYear - 3] = { level: _.cloneDeep(EmptyData), location: _.cloneDeep(EmptyData) };

    const endpointCalls: Array<Promise<void>> = [];
    for (const year of years) {
      // searchCriteria.dateFrom = `${year}-01-01`;
      // searchCriteria.dateTo = `${year}-12-31`;

      const locationQuery: DataServiceAggregateQuery = {
        groupBy: "row:$properties.location,column:$diversity.isIndigenous",
        dateFrom: `${year}-01-01`,
        dateTo: `${year}-12-31`,
        constraints: {},
      };
      const levelQuery: DataServiceAggregateQuery = {
        groupBy: "row:$properties.occupation_category,column:$diversity.isIndigenous",
        dateFrom: `${year}-01-01`,
        dateTo: `${year}-12-31`,
        constraints: {},
      };

      endpointCalls.push(
        getEmploymentAnalysis(locationQuery, org).then((response) => {
          this.CCABYearlyDiversityData[year].location = {
            reportMeta: {} as EIRReportMeta,
            totals: {
              cross_analysis: [],
              employeeAnalysis: response,
            },
          };
        }),
      );
      endpointCalls.push(
        getEmploymentAnalysis(levelQuery, org).then((response) => {
          this.CCABYearlyDiversityData[year].level = {
            reportMeta: {} as EIRReportMeta,
            totals: {
              cross_analysis: [],
              employeeAnalysis: response,
            },
          };
        }),
      );

      // endpointCalls.push(
      //   getReportMatrix(searchCriteria, tenant, org, "$properties.occupation_category", "$diversityDetails.diversityDeclaration.indigenousPeoples")
      //   .then((response) => {
      //     this.CCABYearlyDiversityData[year].level = response;
      //   }),
      // );
      // endpointCalls.push(
      //   getReportMatrix(searchCriteria, tenant, org, "$properties.location", "$diversityDetails.diversityDeclaration.indigenousPeoples")
      //   .then((response) => {
      //     this.CCABYearlyDiversityData[year].location = response;
      //   }),
      // );
    }
    await Promise.all(endpointCalls);

    this.CCABReportMatrixLoading = false;
    this.setIsLoading();
  }

  private async getLevelMatrix(tenant: string, org: string, fields: Array<{ col: string; field: string }>, project: string | null): Promise<void[]> {
    const endpointCalls: Array<Promise<void>> = [];
    for (const entry of fields) {
      endpointCalls.push(
        getReportMatrix(this.searchCriteria, tenant, org, "$properties.occupation_category", entry.col, false, project).then((response) => {
          this.EIRLevelData[entry.field] = response;
        }),
      );
    }

    return Promise.all(endpointCalls);
  }

  private async getLocationMatrix(tenant: string, org: string, fields: Array<{ col: string; field: string }>, project: string | null): Promise<void[]> {
    const endpointCalls: Array<Promise<void>> = [];
    for (const entry of fields) {
      endpointCalls.push(
        getReportMatrix(this.searchCriteria, tenant, org, "$properties.location", entry.col, false, project).then((response) => {
          this.EIRLocationData[entry.field] = response;
        }),
      );
    }

    return Promise.all(endpointCalls);
  }

  private async getOccupationMatrix(tenant: string, org: string, fields: Array<{ col: string; field: string }>, project: string | null): Promise<void[]> {
    const endpointCalls: Array<Promise<void>> = [];
    for (const entry of fields) {
      endpointCalls.push(
        getReportMatrix(this.searchCriteria, tenant, org, "$employment.occupationDescription", entry.col, true, project).then((response) => {
          this.EIRHourlyOccupationData[entry.field] = response;
        }),
      );
    }

    return Promise.all(endpointCalls);
  }

  private async getTotalMatrix(tenant: string, org: string, fields: Array<{ col: string; field: string }>, project: string | null): Promise<void[]> {
    const endpointCalls: Array<Promise<void>> = [];
    for (const entry of fields) {
      endpointCalls.push(
        getReportMatrix(this.searchCriteria, tenant, org, "$expense.expenseClass", entry.col, false, project).then((response) => {
          this.EIRTotalData[entry.field] = response;
        }),
      );
    }

    return Promise.all(endpointCalls);
  }

  private async getProjectVendors(): Promise<void> {
    this.projectVendorsLoading = true;
    this.setIsLoading();

    const selectedProject: string | null = this.getProject(this.searchCriteria);
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    if (selectedProject) {
      this.projectDetails["record"] = await getProject(selectedProject, true);

      const searchParams: SearchParams = _.cloneDeep(EmptySearchParams);
      searchParams.ipp = "50";

      const searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
      searchCriteria.dateFrom = this.searchCriteria.dateFrom;
      searchCriteria.dateTo = this.searchCriteria.dateTo;
      searchCriteria.projects = selectedProject;
      if (selectedCommunity) {
        searchCriteria.beneficiaries = selectedCommunity;
      }

      const aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
      const payeeSearchCriteria: PayeeSearchCriteria = _.cloneDeep(EmptyPayeeSearchCriteria);

      let page = 1;
      const response = await getProjectPayees(searchParams, selectedProject, searchCriteria, aggSearchCriteria, payeeSearchCriteria);
      this.pehtaData["vendors"] = response.searchResults.results as Vendor[];

      while (page * 50 < response.searchResults.resultMeta.matched) {
        page++;
        searchParams.page = page.toString();
        const data = await getProjectPayees(searchParams, selectedProject, searchCriteria, aggSearchCriteria, payeeSearchCriteria);
        this.pehtaData["vendors"].push(...(data.searchResults.results as Vendor[]));
      }

      const org = store.getters["local/CURRENT_ORG"] as string;
      const expenseQuery: DataServiceAggregateQuery = {
        groupBy: "payment.hasPayee",
        dateFrom: this.searchCriteria.dateFrom,
        dateTo: this.searchCriteria.dateTo,
        constraints: {
          "payment.hasProject": selectedProject,
          "expense.expenseType": "project-expense",
        },
      };
      if (selectedCommunity) {
        expenseQuery.constraints["payment.hasBeneficiary"] = selectedCommunity;
      }
      this.pehtaData["vendorExpenses"] = await getExpenseAggregates(expenseQuery, org);
    }

    this.projectVendorsLoading = false;
    this.setIsLoading();
  }

  private async getPehtaVendors(): Promise<void> {
    this.projectVendorsLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    const searchParams: SearchParams = _.cloneDeep(EmptySearchParams);
    searchParams.ipp = "100";

    const searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    searchCriteria.dateFrom = this.searchCriteria.dateFrom;
    searchCriteria.dateTo = this.searchCriteria.dateTo;
    if (selectedCommunity) {
      searchCriteria.beneficiaries = selectedCommunity;
    }

    let page = 1;
    const response = await getVendors(searchParams, "", searchCriteria, org);
    this.pehtaData["vendors"] = response.searchResults.results as Vendor[];

    while (page * 100 < response.searchResults.resultMeta.matched) {
      page++;
      searchParams.page = page.toString();
      const data = await getVendors(searchParams, "", searchCriteria, org);
      this.pehtaData["vendors"].push(...(data.searchResults.results as Vendor[]));
    }

    this.projectVendorsLoading = false;
    this.setIsLoading();
  }

  private async getProjectExpenses(): Promise<void> {
    this.projectExpensesLoading = true;
    this.setIsLoading();

    const selectedProject: string | null = this.getProject(this.searchCriteria);
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    if (selectedProject) {
      const org = store.getters["local/CURRENT_ORG"] as string;
      const expenseQuery: DataServiceAggregateQuery = {
        groupBy: "payment.hasBeneficiary",
        dateFrom: this.searchCriteria.dateFrom,
        dateTo: this.searchCriteria.dateTo,
        constraints: {
          "payment.hasProject": selectedProject,
          "expense.expenseType": "project-expense",
        },
      };
      if (selectedCommunity) {
        expenseQuery.constraints["payment.hasBeneficiary"] = selectedCommunity;
      }
      this.pehtaData["expenses"] = await getExpenseAggregates(expenseQuery, org);

      const projectQuery = _.cloneDeep(expenseQuery);
      projectQuery.groupBy = "payment.hasPayer";
      projectQuery.constraints = {
        "payment.hasProject": selectedProject,
        "expense.expenseType": "project-expense",
      };
      this.pehtaData.project = await getExpenseAggregates(projectQuery, org);

      const searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
      if (selectedCommunity) {
        searchCriteria.beneficiaries = selectedCommunity;
      }
      const aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
      aggSearchCriteria.hasProject = selectedProject;
      aggSearchCriteria.hasPayer = org;

      const res = await searchAggregatesById(searchCriteria, aggSearchCriteria, selectedProject);
      this.pehtaData["projectAgggregate"] = res.searchResults.results[0];
    }

    this.projectExpensesLoading = false;
    this.setIsLoading();
  }

  private async getPehtaExpenses(): Promise<void> {
    this.projectExpensesLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    const expenseQuery: DataServiceAggregateQuery = {
      groupBy: "payment.hasBeneficiary",
      dateFrom: this.searchCriteria.dateFrom,
      dateTo: this.searchCriteria.dateTo,
      constraints: {
        "expense.expenseType": "project-expense",
      },
    };
    if (selectedCommunity) {
      expenseQuery.constraints["payment.hasBeneficiary"] = selectedCommunity;
    }

    this.pehtaData["expenses"] = await getExpenseAggregates(expenseQuery, org);

    const projectQuery = _.cloneDeep(expenseQuery);
    projectQuery.groupBy = "payment.hasPayer";
    projectQuery.constraints = {
      "payment.hasPayer": org,
      "expense.expenseType": "project-expense",
    };
    this.pehtaData.overall = await getExpenseAggregates(projectQuery, org);

    this.projectExpensesLoading = false;
    this.setIsLoading();
  }

  private async getProjectEmployment(): Promise<void> {
    this.projectEmploymentLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const selectedProject: string | null = this.getProject(this.searchCriteria);
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    if (selectedProject) {
      const employmentQuery: DataServiceAggregateQuery = {
        groupBy: "payment.hasBeneficiary",
        dateFrom: this.searchCriteria.dateFrom,
        dateTo: this.searchCriteria.dateTo,
        constraints: {
          "payment.hasProject": selectedProject,
          "common.recordType": "Employment",
        },
      };

      this.pehtaData.totalEmployment = await getEmploymentAggregates(employmentQuery, org);

      if (selectedCommunity) {
        employmentQuery.constraints["payment.hasBeneficiary"] = selectedCommunity;
      }
      this.pehtaData.employment = await getEmploymentAggregates(employmentQuery, org);

      const indigenousEmploymentQuery = _.cloneDeep(employmentQuery);
      indigenousEmploymentQuery.groupBy = "diversity.isIndigenous";
      this.pehtaData.totalIndigenousEmployment = await getEmploymentAggregates(indigenousEmploymentQuery, org);

      if (!selectedCommunity) {
        this.inferIndigenousEmployment(this.pehtaData.totalIndigenousEmployment, this.pehtaData.employment);
      }
    }

    this.projectEmploymentLoading = false;
    this.setIsLoading();
  }

  private async getPehtaEmployment(): Promise<void> {
    this.projectEmploymentLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    const employmentQuery: DataServiceAggregateQuery = {
      groupBy: "properties.occupation_category",
      dateFrom: this.searchCriteria.dateFrom,
      dateTo: this.searchCriteria.dateTo,
      constraints: {
        "payment.hasPayer": org,
        "common.recordType": "Employment",
      },
    };
    if (selectedCommunity) {
      employmentQuery.constraints["payment.hasBeneficiary"] = selectedCommunity;
    }

    this.pehtaData.employment = await getEmploymentAggregates(employmentQuery, org);

    const indigenousEmploymentQuery: DataServiceAggregateQuery = _.cloneDeep(employmentQuery);
    indigenousEmploymentQuery.constraints["diversity.isIndigenous"] = "yes";
    if (selectedCommunity) {
      indigenousEmploymentQuery.constraints["payment.hasBeneficiary"] = selectedCommunity;
    }
    this.pehtaData.indigenousEmployment = await getEmploymentAggregates(indigenousEmploymentQuery, org);

    const communityEmploymentQuery: DataServiceAggregateQuery = _.cloneDeep(indigenousEmploymentQuery);
    communityEmploymentQuery.groupBy = "payment.hasBeneficiary";
    if (selectedCommunity) {
      communityEmploymentQuery.constraints["payment.hasBeneficiary"] = selectedCommunity;
    }
    this.pehtaData.communityEmployment = await getEmploymentAggregates(communityEmploymentQuery, org);

    const totalIndigenousEmploymentQuery = _.cloneDeep(communityEmploymentQuery);
    totalIndigenousEmploymentQuery.groupBy = "diversity.isIndigenous";
    this.pehtaData.totalIndigenousEmployment = await getEmploymentAggregates(totalIndigenousEmploymentQuery, org);

    if (!selectedCommunity) {
      this.inferIndigenousEmployment(this.pehtaData.totalIndigenousEmployment, this.pehtaData.communityEmployment);
    }

    this.projectEmploymentLoading = false;
    this.setIsLoading();
  }

  private inferIndigenousEmployment(totalIndigenousEmployment: EmploymentAggregate[], employment: EmploymentAggregate[]): void {
    // Infer Indigenous(General) employment
    const indigenousRecord = totalIndigenousEmployment.find((e) => e.identifier === "yes");
    if (indigenousRecord) {
      const { totalIndigenousHeadcount, totalIndigenousManHours, totalIndigenousWages } = employment.reduce(
        (totals, vendor) => {
          if (vendor.identifier !== "urn:nisto-link:id:non-indigenous:no-999999") {
            totals.totalIndigenousHeadcount += vendor.payload.employment.headcount;
            totals.totalIndigenousManHours += vendor.payload.employment.totalManHours;
            totals.totalIndigenousWages += vendor.payload.employment.totalWages;
          }
          return totals;
        },
        { totalIndigenousHeadcount: 0, totalIndigenousManHours: 0, totalIndigenousWages: 0 },
      );

      const headcount = indigenousRecord.payload.employment.headcount - totalIndigenousHeadcount;
      const totalManHours = indigenousRecord.payload.employment.totalManHours - totalIndigenousManHours;
      const totalWages = indigenousRecord.payload.employment.totalWages - totalIndigenousWages;

      if (headcount || totalManHours || totalWages) {
        const indigenousGeneralEmployment: EmploymentAggregate = {
          identifier: "urn:nisto-link:id:indigenous-general:no-777777",
          displayName: "Indigenous (General)",
          payload: {
            employment: {
              averageRate: 0,
              headcount,
              totalManHours,
              totalWages,
            },
          },
        };
        employment.push(indigenousGeneralEmployment);
      }
    }
  }

  private async getProjectContributions(): Promise<void> {
    this.projectContributionsLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const selectedProject: string | null = this.getProject(this.searchCriteria);
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    if (selectedProject) {
      const expenseQuery: DataServiceAggregateQuery = {
        groupBy: "payment.hasPayee",
        dateFrom: this.searchCriteria.dateFrom,
        dateTo: this.searchCriteria.dateTo,
        constraints: {
          "payment.hasProject": selectedProject,
          "expense.expenseClass": "contribution",
        },
      };
      if (selectedCommunity) {
        expenseQuery.constraints["payment.hasBeneficiary"] = selectedCommunity;
      }

      let page = 1;
      const searchParams: SearchParams = _.cloneDeep(EmptySearchParams);
      searchParams.ipp = "30";
      const searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);

      const expensesSearchCriteria: SearchExpenses = _.cloneDeep(EmptySearchExpenses);
      expensesSearchCriteria.projects = [selectedProject];
      expensesSearchCriteria.expenseClasses = ["contribution"];
      if (selectedCommunity) {
        expensesSearchCriteria.beneficiaries = [selectedCommunity];
      }

      const response = await searchExpenses(searchParams, searchCriteria, expensesSearchCriteria);
      this.pehtaData["vendorContributions"] = response.searchResults.results as Expense[];

      while (page * 30 < response.searchResults.resultMeta.matched) {
        page++;
        searchParams.page = page.toString();
        const data = await searchExpenses(searchParams, searchCriteria, expensesSearchCriteria);
        this.pehtaData["vendorContributions"].push(...(data.searchResults.results as Expense[]));
      }

      const contributionExpenseQuery = _.cloneDeep(expenseQuery);
      contributionExpenseQuery.groupBy = "payment.hasBeneficiary";
      this.pehtaData.communityContributions = await getExpenseAggregates(contributionExpenseQuery, org);
    }

    this.projectContributionsLoading = false;
    this.setIsLoading();
  }

  private async getPehtaContributions(): Promise<void> {
    this.projectContributionsLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);

    const expenseQuery: DataServiceAggregateQuery = {
      groupBy: "payment.hasPayee",
      dateFrom: this.searchCriteria.dateFrom,
      dateTo: this.searchCriteria.dateTo,
      constraints: {
        "payment.hasPayer": org,
        "expense.expenseClass": "contribution",
      },
    };
    if (selectedCommunity) {
      expenseQuery.constraints["payment.hasBeneficiary"] = selectedCommunity;
    }

    let page = 1;
    const searchParams: SearchParams = _.cloneDeep(EmptySearchParams);
    searchParams.ipp = "50";

    const searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    searchCriteria.dateFrom = this.searchCriteria.dateFrom;
    searchCriteria.dateTo = this.searchCriteria.dateTo;

    const expensesSearchCriteria: SearchExpenses = _.cloneDeep(EmptySearchExpenses);
    expensesSearchCriteria.expenseClasses = ["contribution"];
    expensesSearchCriteria.payers = [org];
    if (selectedCommunity) {
      expensesSearchCriteria.beneficiaries = [selectedCommunity];
    }

    const response = await searchExpenses(searchParams, searchCriteria, expensesSearchCriteria);
    this.pehtaData["vendorContributions"] = response.searchResults.results as Expense[];

    while (page * 50 < response.searchResults.resultMeta.matched) {
      page++;
      searchParams.page = page.toString();
      const data = await searchExpenses(searchParams, searchCriteria, expensesSearchCriteria);
      this.pehtaData["vendorContributions"].push(...(data.searchResults.results as Expense[]));
    }

    const contributionExpenseQuery = _.cloneDeep(expenseQuery);
    contributionExpenseQuery.groupBy = "payment.hasBeneficiary";
    this.pehtaData.communityContributions = await getExpenseAggregates(contributionExpenseQuery, org);

    this.projectContributionsLoading = false;
    this.setIsLoading();
  }

  private async getIndigenousCommunities(excludeList: string[] = []) {
    this.icsLoading = true;
    this.setIsLoading();

    this.indigenousCommunities = (await getShortList("IndigenousCommunity", false, [], false, "", [])).searchResults.results;

    this.icsLoading = false;
    this.setIsLoading();
  }

  private async getUNSDGExpenses(): Promise<void> {
    this.unsdgExpensesLoading = true;
    this.setIsLoading();
    this.expenseData = {};

    this.unsdgs = (await getReferenceData("contribution-kinds")).refData.values;
    for (const unsdg of this.unsdgs) {
      await this.searchUNSDGExpenses(unsdg.code);
    }

    this.unsdgExpensesLoading = false;
    this.setIsLoading();
  }

  private async searchUNSDGExpenses(unsdg: string) {
    const org = store.getters["local/CURRENT_ORG"] as string;
    const selectedCommunity: string | null = this.getCommunity(this.searchCriteria);
    const selectedProject: string | null = this.getProject(this.searchCriteria);

    const searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    const expensesSearchCriteria: SearchExpenses = _.cloneDeep(EmptySearchExpenses);
    const searchParams: SearchParams = _.cloneDeep(EmptySearchParams);

    // expensesSearchCriteria.expenseClasses = ["contribution"];
    expensesSearchCriteria.unsdg = [unsdg];
    searchParams.ipp = "30";
    let page = 1;

    if (selectedProject) {
      expensesSearchCriteria.projects = [selectedProject];
    }
    if (selectedCommunity) {
      expensesSearchCriteria.beneficiaries = [selectedCommunity];
    }

    const response = await searchExpenses(searchParams, searchCriteria, expensesSearchCriteria);
    this.expenseData[unsdg] = response.searchResults.results as Expense[];

    while (page * 30 < response.searchResults.resultMeta.matched) {
      page++;
      searchParams.page = page.toString();
      const data = await searchExpenses(searchParams, searchCriteria, expensesSearchCriteria);
      this.expenseData[unsdg].push(...(data.searchResults.results as Expense[]));
    }
  }

  private async getInclusionDevelopmentAgreementData(): Promise<void> {
    this.inclusionDevelopmentAgreementLoading = true;
    this.setIsLoading();

    const org = store.getters["local/CURRENT_ORG"] as string;
    const selectedProject = this.getProject(this.searchCriteria);
    if (!selectedProject) {
      this.inclusionDevelopmentAgreementLoading = false;
      this.setIsLoading();
      return;
    }

    const expenseQuery: DataServiceAggregateQuery = {
      dateFrom: this.searchCriteria.dateFrom,
      dateTo: this.searchCriteria.dateTo,
      constraints: {
        "payment.hasProject": selectedProject,
      },
    };

    this.inclusionDevelopmentAgreementData = await getEidaEmployment(expenseQuery, org);
    await this.getProjectDetails([selectedProject]);

    this.inclusionDevelopmentAgreementLoading = false;
    this.setIsLoading();
  }
}
